import * as React from 'react';
import {Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Grid, Box, Typography, Container, ThemeProvider, createTheme, makeStyles} from '@material-ui/core'
import { Lock } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        CSC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
    container: {
        border: "1px solid grey",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        backgroundColor: "white",
    },
}));
const theme = createTheme();

export default function SignIn() {
    const classes = useStyles();
    const initialValues2 = { username: "", email: "", password: "" };
    const [formValues2, setFormValues2] = useState(initialValues2);
    const [formErrors2, setFormErrors2] = useState({});
    const [loading, setLoading] = useState(true)
    const { login } = useAuth();
    const history = useHistory();
  
    const handleChange2 = (e) => {
      const { name, value } = e.target;
      setFormValues2({ ...formValues2, [name]: value });
    };
  
    const validate2 = (values) => {
      const errors = {};
      errors.email = "Invalid Credentials";
      errors.password = "Invalid Credentials";
      return errors;
    };
  
    async function handleSubmit2(e) {
      e.preventDefault();
      try {
        setFormErrors2({});
        setLoading(true);
        await login(formValues2.email, formValues2.password, formValues2.username);
        history.push("/");
      } catch {
        setFormErrors2(validate2(formValues2));
      }
      setLoading(false);
    };

  return (
    <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '90vh' }}
  >
  
    <Grid item xs={11} className = {classes.container}>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <Lock />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit2} noValidate sx={{ mt: 2 }}>
            <TextField
            variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formValues2.email} onChange={handleChange2} error = {Boolean(formErrors2?.email)} helperText = {formErrors2?.email}
            />
            <TextField
            variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
                value={formValues2.password} onChange={handleChange2} error = {Boolean(formErrors2?.password)} helperText = {formErrors2?.password}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color = 'primary'
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/reset-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item xs>
                <Link to="/sign-up" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        
      </Container>
    </ThemeProvider>
    </Grid>   
     
  </Grid> 
    
  );
}