import React, { useState, useEffect } from 'react'
import { Grid, Theme, createTheme, makeStyles, alpha, Typography, ThemeProvider, InputBase } from '@material-ui/core'
import { Search, Cancel } from "@material-ui/icons";
import Post from "../FindLawyer/Post"
import { firestore} from "../../firebase"

const useStyles = makeStyles((theme) => ({
  search: {
    display: "flex",
    alignItems: "center",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    borderRadius: theme.shape.borderRadius,
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      display: (props) => (props.open ? "flex" : "none"),
      width: "70%",
    },
  },
  searchButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  cancel: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  leftProfile: {
    marginLeft: theme.spacing(20),
    marginTop: theme.spacing(10),
  },
}));

const theme = createTheme({
  typography: {
    h1: {
      fontSize: 36,
      fontFamily: "Roboto",
      color: "#3D5A80",
      fontWeight: 'bold' 
    },
  },
});

const FindLawyer = () => {
    const [open, setOpen] = useState(false);
    const [searchBar, setSearchBar] = useState("");
    const [messages, setMessages] = useState({});
    useEffect(() => {
        firestore.collection('Users').where("isLawyer", "==", true).limit(12).get().then((collection) => {
            setMessages(collection.docs);
        });;
    }, []); 
    const classes = useStyles();

    function searchFilter(element) {
      const filterText = searchBar.toLowerCase()
      return element.data().username.toLowerCase().startsWith(filterText) || element.data().fieldoflaw.toLowerCase().startsWith(filterText) || element.data().city.toLowerCase().startsWith(filterText)
    }

    const handleChange = (e) => {
      e.preventDefault();
      const { value } = e.target;
      setSearchBar(value);
    };

    return (
      <ThemeProvider theme={theme}>
        <Grid container direction = "row" spacing={2} md = {10} className ={classes.leftProfile} >
            <Grid item md={12} xs = {3}>
              <Typography variant = "h1">
                FIND A LAWYER
              </Typography>
            </Grid>
            <Grid item md={12}>
              <div className={classes.search}>
                <Search />
                  <InputBase placeholder="Search by name, field of law, area..." className={classes.input} onChange={handleChange} fullWidth/>
                <Cancel className={classes.cancel} onClick={() => setOpen(false)} />
              </div>
            </Grid>
            <Grid item container md={12} spacing = {6} direction = "row">
              {Array.isArray(messages) &&  messages.filter(searchFilter).map(msg => <Grid item md={2} xs = {3}>
                <Post title={msg.data().username} img="https://www.w3schools.com/howto/img_avatar.png" fieldOfLaw = {msg.data().fieldoflaw} id = {msg.id} area = {msg.data().city}/>
              </Grid>)}
            
            </Grid>
        </Grid>
      </ThemeProvider>
        
      );
}

export default FindLawyer;