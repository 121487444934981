import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import SignupPage from './components/pages/SignupPage';
import RequestQuote from './components/pages/RequestQuote';
import PostExample from './components/pages/PostExample';
import Post from './components/pages/Post';
import FindLawyer from './components/pages/FindLawyer';
import Billboard from './components/pages/Billboard'
import SignUpClient from './components/pages/SignupClient'
import SignUpLawyer from './components/pages/SignupLawyer'
import Login from './components/pages/Login'
import ResetPassword from './components/pages/ResetPassword'
import Profile from './components/pages/Profile'
import Payment from './components/pages/Payment'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthProvider } from "./contexts/AuthContext"
import  Messages  from "./components/pages/Messages"
import  MyCases  from "./components/pages/MyCases"
import PrivateRouteClient from "./PrivateRouteClient"
import PrivateRouteLawyer from "./PrivateRouteLawyer"

function App() {
  document.body.style = 'background: #dfffff;';
  return (
    <AuthProvider>
      <Router>
        <Navbar>

        </Navbar>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/sign-up' component={SignupPage} />
          <PrivateRouteClient path='/request-quote' component={RequestQuote} />
          <Route path='/post/1' component={PostExample} />
          <PrivateRouteClient path='/find-lawyer' component={FindLawyer} />
          <Route path='/billboard' component={Billboard} />
          <Route path='/register/client' component={SignUpClient} />
          <Route path='/register/lawyer' component={SignUpLawyer} />
          <Route path='/login' component={Login} />
          <Route path='/reset-password' component={ResetPassword} />
          <Route path="/posts/:id" component={Post}/> 
          <Route path="/profile/:id" component={Profile}/> 
          <Route path="/chat/:id" component={Messages}/> 
          <Route path="/payment/" component={Payment}/> 
          <Route path="/my-cases/" component={MyCases}/> 
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;