import { Container, makeStyles, Typography, Grid,  ButtonBase, styled, Button, TextField, InputAdornment} from "@material-ui/core";
import { Link } from 'react-router-dom';
import ProfileInfo from "../Profile/ProfileInfo"
import CaseLink from "../Profile/CaseLink"
import { firestore} from "../../firebase"
import React, { useContext, useState, useEffect } from "react"
import { useAuth } from "../../contexts/AuthContext"

const Img = styled('img')({
    borderRadius: "50%",
    marginTop: 60,
    display: 'block',
    maxWidth: '60%',
    maxHeight: '60%',
  });

const Icon = styled('img')({
    height: 24,
    width: 48,
    justifyContent: "center",
  });

const useStyles = makeStyles((theme) => ({
  container: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    position: "sticky",
    top: 0,
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "white",
      color: "#555",
      border: "1px solid #ece7e7",
    },
  },
  containerRight: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    position: "sticky",
    top: 0,
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "white",
      color: "#555",
      border: "1px solid #ece7e7",
    },
  },
  container2: {
    paddingTop: theme.spacing(10),
  },
  card: {
    marginBottom: theme.spacing(5),
  },
  leftProfile: {
    marginLeft: theme.spacing(10),
    marginTop: theme.spacing(5),
  },
  bottomProfile: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  }
}));

const Leftbar = () => {

  const classes = useStyles();
  const id = window.location.href.substring(window.location.href.search("/profile/") + 9);
  const isMyProfile = id == useAuth().currentUser.uid;
  const [profile, setProfile] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  useEffect(() => {
      firestore.collection('Users').doc(id).get().then((collection) => {
          setProfile(collection.data());
      });;
  }, [refreshKey]); 
  
  const caseData = [
    {
        link: "https://www.canlii.org/en/on/onscsm/doc/2021/2021canlii130291/2021canlii130291.html",
        title: "Royal One 2225 Markham Road Med Centre Ltd",
        won: true,
    },
    {
        link: "https://www.canlii.org/en/on/onscsm/doc/2021/2021canlii57303/2021canlii57303.html",
        title: "Valiant Rental Properties Ltd. v Paulauskas",
        won: true,
    },
    {
        link: "https://www.canlii.org/en/on/onscsm/doc/2022/2022canlii1999/2022canlii1999.html",
        title: "Maclaren v Pregel",
        won: false,
    },
    {
        link: "https://www.canlii.org/en/on/onscsm/doc/2021/2021canlii124429/2021canlii124429.html",
        title: "Regional Municipality Malone of Durham v Mammoon",
        won: true,
    },
    {
        link: "https://www.canlii.org/en/on/onscsm/doc/2021/2021canlii64677/2021canlii64677.htmll",
        title: "Shirazi v Lindvest properties limited",
        won: false,
    },
    {
        link: "https://www.canlii.org/en/on/onscsm/doc/2021/2021canlii85041/2021canlii85041.html",
        title: "Chambers v Ontario",
        won: true,
    },
    {
        link: "https://www.canlii.org/en/on/onscsm/doc/2021/2021canlii58973/2021canlii58973.html",
        title: "Karami v Hovsepian Ouatik Dentistry Professional Corporation",
        won: true,
    },
]

const initialValues2 = { description: profile.description, offer: "" };
const [formValues2, setFormValues2] = useState(initialValues2);
const [formErrors2, setFormErrors2] = useState({});

const validate2 = (values) => {
    const errors = {};
    if (values.description && values.description.length >= 500) {
        errors.description = "Maximum character length 500!";
    }
    console.log("HELLO", values.offer == "");
    if ((isNaN(values.offer) && values.offer && values.offer.trim() != "") || (isNaN(values.offer[0]) && values.offer != "")) {
        errors.offer = "Offer must be a number!"
    }
    return errors;
};

const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormValues2({ ...formValues2, [name]: value });
};

const handleEdit = async (e) => {
    if (editMode) {
        const errorList = validate2(formValues2);
        if (Object.keys(errorList).length === 0) {
            setFormErrors2({});
            const offer = formValues2.offer && formValues2.offer.trim() != "";
            const profileRef = firestore.collection('Users').doc(id);
            await profileRef.update({
                fee: offer ? formValues2.offer : null,
                description: formValues2.description,
                offersServices: offer,
            })
            setRefreshKey(oldKey => oldKey +1)
            setEditMode(false);
            
        } else{
            setFormErrors2(errorList);
        }
    } else {
        setFormValues2({ ...formValues2, ["description"]: profile.description });
        setEditMode(true);
    }
}

  return (
    <Grid container direction = "row" justifyContent="center">
        <Grid item md={2} xs={12} className ={classes.container2}>
            <Grid container spacing={0} className = {classes.container}>
                <Grid item >
                    <ButtonBase sx={{ my: 1, mx: 'auto', p: 2, width: 128, height: 128 }}>
                        <Img alt="complex" src="https://www.w3schools.com/howto/img_avatar.png" />
                    </ButtonBase>
                </Grid>
                <Grid item sm = {12}>
                    <Grid style = {{margin: "auto", alignItems: "center"}}item xs container direction="column" spacing={2}>
                        
                        {!useAuth().profile.isLawyer && <Grid item>
                            <Link to={"/chat/"+id}>
                                <ButtonBase>
                                    <Icon alt="complex" src="https://flyclipart.com/thumb2/mail-icon-mail-multimedia-icon-with-png-and-vector-format-18627.png" />
                                </ButtonBase>
                            </Link>
                        </Grid>}

                        <Grid item xs>
                            <Typography align = "center" variant = "h4">
                                {profile.username}
                            </Typography>
                        </Grid>
                        
                    
                        <Grid item>
                            <Typography align = "center" variant="subtitle1">
                                {profile.fieldoflaw}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid style = {{margin: "auto"}} item xs container spacing={6} sm = {12} justifyContent="space-around">
                        <Grid item >
                            <Typography align = "center" variant="h6">
                            5
                            </Typography>
                            <Typography align = "center" variant="subtitle1">
                            Cases Won
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography align = "center" variant="h6">
                            7
                            </Typography>
                            <Typography align = "center" variant="subtitle1">
                            Total Cases
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item sm={8} xs = {12}>
            <Container className={classes.container2}>
                <Grid container spacing={0} className = {classes.containerRight}>
                    <Grid item className = {classes.leftProfile}>
                        <Typography align = "center" variant = "h5">
                            About
                        </Typography>
                    </Grid>
                    <Grid item sm = {12}>
                        <Grid style = {{margin: "auto"}} item xs container spacing={6} justifyContent="space-around">
                            <Grid item sm = {4} container spacing = {3}>
                                <Grid item xs = {12}>
                                    <Typography align = "left" variant="h6">
                                        User Details
                                    </Typography>
                                </Grid>
                                <ProfileInfo title = "Email" body = {profile.email}> </ProfileInfo>
                                <ProfileInfo title = "Country" body = "Canada"> </ProfileInfo>
                                <ProfileInfo title = "Province/State" body = {profile.province}> </ProfileInfo>
                                <ProfileInfo title = "City" body = {profile.city}> </ProfileInfo>
                                <ProfileInfo title = "Law Firm Name" body = {profile.lawfirmname}> </ProfileInfo>
                                <ProfileInfo title = "Speciality" body = {profile.fieldoflaw}> </ProfileInfo>
                            </Grid>
                            <Grid item sm = {4} container spacing = {1}>
                                <Grid item xs = {12}>
                                    <Typography align = "left" variant="h6">
                                        Lawyer Stats
                                    </Typography>
                                </Grid>
                                <ProfileInfo title = "Total Cases" body = "7"> </ProfileInfo>
                                <ProfileInfo title = "Cases Won" body = "5"> </ProfileInfo>
                                <ProfileInfo title = "Winrate" body = "71.4%"> </ProfileInfo>
                                <Grid item xs = {12}>
                                    <Typography align = "left" variant="subtitle2">
                                        Case History
                                    </Typography>
                                    
                                    {caseData.map(data => <CaseLink link = {data.link} title={data.title} result={data.won}/>)}
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className = {classes.bottomProfile} container spacing = {editMode ? 2 : 5}>
                        <Typography align = "left" variant = "h5">
                            Services
                        </Typography>
                        {editMode && <Grid item xs = {12} >
                            <TextField
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$/hr</InputAdornment>,
                                  }}
                                  size="small"
                                variant="outlined"
                                fullWidth
                                label="offer"
                                name="offer" value={formValues2.offer} onChange={handleChange2} error = {Boolean(formErrors2?.offer)} helperText = {formErrors2?.offer} fullWidth label="Consultation Fee" defaultValue={profile.fee ? profile.fee : ""}/>
                        </Grid>}
                        {editMode && <Grid item xs = {12}>
                            <TextField
                            size="small"
                            multiline rows={7} 
                                variant="outlined"
                                fullWidth
                                label="Description"
                                name="description" value={formValues2.description} onChange={handleChange2} error = {Boolean(formErrors2?.description)} helperText = {formErrors2?.description} fullWidth placeholder="(Max 500 characters)" defaultValue = {profile.description}/>
                        </Grid>}
                        {!editMode && <Grid item sm={12}>
                            <Typography align = "center" variant = "body">
                                {profile.description}                                
                            </Typography>
                        </Grid>}
                        <Grid container>
                            {(profile.offersServices || !isMyProfile) && <Grid item sm = {10}>
                            <Link to={isMyProfile || !profile.offersServices ? "#" : "/payment"} style = {{textDecoration: "none"}}>
                                <Button type="submit" variant="contained" color = 'primary'>                      
                                    {profile.offersServices ? "$" + profile.fee + "/hr" : "SERVICE NOT SET"}
                                </Button>
                                </Link>
                            </Grid> }
                            {isMyProfile && <Grid item sm = {2}>
                                <Button type="submit" variant="contained" color = 'primary' onClick = {handleEdit} style = {{align: "right"}}>                      
                                    Edit Services
                                </Button>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    </Grid>
  );
};

export default Leftbar;
