import React from 'react'
import { Grid, Typography} from '@material-ui/core'
import { Link } from 'react-router-dom';

function BillboardBase({link, title, result}) {
    return (
    <Grid item xs = {12}>
        <Typography align = "left" variant="body">
            <Link to={{ pathname: link }} target="_blank">
            {title + (result ? " (won)" : " (lost)")}
            </Link>
        </Typography>
    </Grid>
    );
}

export default BillboardBase;
 
