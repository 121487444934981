import React from 'react'
import { Grid, Paper, Typography} from '@material-ui/core'
import { Link } from 'react-router-dom';

function BillboardBase({title, body}) {
    return (
        <Grid item xs = {12}>
            <Typography align = "left" variant="subtitle2">
                {title}
            </Typography>
            <Typography align = "left" variant="body">
                {body}
            </Typography>
        </Grid >
    );
}

export default BillboardBase;