import React, {useState, useEffect} from 'react';
import { firestore} from "../firebase"

import {AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem, createTheme} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import Logo from '../images/logo_notext.png'
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'


const pages = ['Find A Lawyer', 'Request a Quote'];
const to = {
  ["Find A Lawyer"] : "/find-lawyer",
  ["Request a Quote"] : "/request-quote",
}
const settings = ['Profile', 'Logout', 'Sign Up', 'Sign In'];



const theme = createTheme({
  typography: {
    subtitle1: {
      fontSize: 24,
    },
    body1: {
      fontWeight: 500,
    },
    button: {
      fontStyle: 'italic',
    },
  },
});


const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { currentUser, logout, profile } = useAuth();
    useEffect(() => {
        
    }, []); 

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  console.log(profile)
  console.log(useAuth().profile)
  return (
    <AppBar position="static" style = {{minHeight: "80px", backgroundColor: "#3c5c81", color: "#e0fbfc"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          
          <img src = {Logo} width = "7%" height = "7%" style = {{padding: "5px", paddingRight: "18px"}}>
          </img>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Link to= "/" style = {{textDecoration: "none", color: "#e0fbfc"}}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
            >
              
              LegalGlow
            </Typography>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
          
          </Typography>
          <Box >

              {!profile.isLawyer && <Link to= {"/find-lawyer"} style = {{textDecoration: "none"}}>
                <Button
                  key="Find a Lawyer"
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  style = {{color: "#e0fbfc", fontSize: 18}}
                >
                  Find a Lawyer
                </Button>
              </Link>}
              {!profile.isLawyer && <Link to= {"/request-quote"} style = {{textDecoration: "none"}}>
                <Button
                  key="Request Quote"
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  style = {{color: "#e0fbfc", fontSize: 18}}
                >
                  Request Quote
                </Button>
              </Link>}
              {currentUser && !profile.isLawyer && <Link to= {"/my-cases"} style = {{textDecoration: "none"}}>
                <Button
                  key="My Cases"
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  style = {{color: "#e0fbfc", fontSize: 18}}
                >
                  My Cases
                </Button>
              </Link>}
              {profile.isLawyer && <Link to= {"/billboard"} style = {{textDecoration: "none"}}>
                <Button
                  key="Billboard"
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  style = {{color: "#e0fbfc", fontSize: 18}}
                >
                  View Cases
                </Button>
              </Link>}
              {currentUser && <Link to= {"/chat/default"} style = {{textDecoration: "none"}}>
                <Button
                  key="Chat"
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  style = {{color: "#e0fbfc", fontSize: 18}}
                >
                  View Messages
                </Button>
              </Link>}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {Boolean(currentUser) && profile.isLawyer && <Link
                to={'/profile/'+currentUser.uid}
                style={{color: "#000000", textDecoration: 'none' }}
              >
              <MenuItem key="Profile" onClick={handleCloseUserMenu}>
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              </Link>}
              {Boolean(currentUser) && <Link
                to='/login'
                style={{color: "#000000", textDecoration: 'none' }}
              >
              <MenuItem key="Logout" onClick={logout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
              </Link>}
              {!Boolean(currentUser) && <Link
                to={'/sign-up'}
                style={{color: "#000000", textDecoration: 'none' }}
              >
              <MenuItem key="Sign Up" onClick={handleCloseUserMenu}>
                <Typography textAlign="center">Sign Up</Typography>
              </MenuItem>
              </Link>}
              {!Boolean(currentUser) && <Link
                to={'/login'}
                style={{color: "#000000", textDecoration: 'none' }}
              >
              <MenuItem key="Log In" onClick={handleCloseUserMenu}>
                <Typography textAlign="center">Log In</Typography>
              </MenuItem>
              </Link>}
              
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
