import React, { useContext, useState, useEffect } from "react"
import { auth } from "../firebase"
import { firestore } from "../firebase"

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
  }

  export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [profile, setProfile] = useState({});
  
    function signup(email, password, username) {
      return auth.createUserWithEmailAndPassword(email, password);
    }
  
    function login(email, password) {
      return auth.signInWithEmailAndPassword(email, password)
    }
  
    function logout() {
      setProfile({});
      return auth.signOut()
    }
  
    function resetPassword(email) {
      return auth.sendPasswordResetEmail(email)
    }
  
    function updateEmail(email) {
      return currentUser.updateEmail(email)
    }
  
    function updatePassword(password) {
      return currentUser.updatePassword(password)
    }

    function updateUserData(data) {
      setProfile(data)
      return firestore.collection("Users").doc(auth.currentUser.uid).set(data);
    }

    function updateProfile(data) {
      const newProfile = {
        displayName: data.username,
        phoneNumber: data.phone,
        firm: data.lawfirmname,
        province: data.province,
        city: data.city,
        field: data.fieldoflaw,
        photoURL: "https://example.com/jane-q-user/profile.jpg"
      }
      return auth.currentUser.updateProfile(newProfile)
    }
  
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        setCurrentUser(user)
        setLoading(false)
        firestore.collection('Users').doc(user.uid).get().then((collection) => {
          console.log("Setting profile data")
          console.log("DATA:", collection.data())
          if (collection.data()) {
            setProfile(collection.data());
          }

        });;
      })
  
      return unsubscribe
    }, [])
  
    const value = {
      currentUser,
      login,
      signup,
      logout,
      resetPassword,
      updateEmail,
      updatePassword,
      updateProfile,
      updateUserData,
      profile
    }
  
    return (
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
    )
  }