import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    makeStyles,
    Typography,
    createTheme,
    ThemeProvider,
    Grid
  } from "@material-ui/core";
  import { Link } from 'react-router-dom';
  const useStyles = makeStyles((theme) => ({
    media: {
      height: 150,
      [theme.breakpoints.down("sm")]: {
        height: 100,
      },
    },
  }));
  
  const theme = createTheme({
    typography: {
      h1: {
        fontSize: 24,
        fontFamily: "Roboto",
        color: "#3D5A80",
        fontWeight: 'bold' 
      },
      h2: {
        fontSize: 18,
        fontFamily: "Roboto",
        color: "#EE6C4D",
      },
    },
  });

  const Post = ({ img, title, fieldOfLaw, id, area }) => {
    const classes = useStyles();
    return (
      <ThemeProvider theme={theme}>
          <Link to={"/profile/"+id} style={{textDecoration: 'none' }}>
            <Card>
              <CardActionArea>
                <CardMedia className={classes.media} image={img} title="My Post" />
                <CardContent>
                  <Typography gutterBottom variant="h1">
                    {title}
                  </Typography>
                  <Grid container xs = {12}>
                    <Grid item xs = {6}>
                      <Typography variant="h2">
                        {fieldOfLaw}
                    </Typography>
                    </Grid>
                    <Grid item xs = {6}>
                      <Typography variant="h2" align = "right">
                        {area}
                      </Typography>
                    </Grid>
                  </Grid>
                
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
          
      </ThemeProvider>
      
    );
  };
  
  export default Post;
  