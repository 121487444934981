 import React, { useContext, useState, useEffect } from "react"
import { Grid, Typography,  Box,  makeStyles} from '@material-ui/core'

import PostRightPanel from '../Posts/PostRightPanel';
import PostComment from '../Posts/PostComment';
import PostNewComment from '../Posts/PostNewComment';
import { firestore} from "../../firebase"
import { useAuth } from '../../contexts/AuthContext'


const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(10),
    },
  }));

function RequestQuote() {
    const classes = useStyles();
    const [data, setData] = useState({});
    const [messages, setMessages] = useState({});
    const id = window.location.href.substring(window.location.href.search("/posts/") + 7);
    const { profile, currentUser } = useAuth();
    useEffect(() => {
        firestore.collection('Posts').doc(id).get().then((doc) => {
            setData(doc.data());
        });
        firestore.collection('Posts').doc(id).collection("Comments").orderBy('date').limit(25).onSnapshot((doc) => {
            setMessages(doc.docs);
            console.log(doc.docs)
        });;
    }, []);
    return (
        <Grid container spacing={2} direction="row" justifyContent="space-evenly" className = {classes.container}>
            <Grid item xs = {8}>
                <Box sx={{flexGrow: 1, overflow: 'hidden', px: 3, backgroundColor: "white"}}>
                    <PostComment name = {data.name} isAsker = {true} comment ={data.text}></PostComment>
                    <Typography>
                        {messages && Object.keys(messages).length > 0 ? "Responses" : ""}
                    </Typography>
                    {messages && Object.keys(messages).length > 0 && messages.map(msg => <PostComment name = {msg.data().name} isAsker = {false} commenterId = {msg.data().uid}offer = {msg.data().Offer} comment ={msg.data().Comment} postOwner = {currentUser && currentUser.uid == data.uid}></PostComment>)}
                    
                    {profile.isLawyer && <PostNewComment id = {id}></PostNewComment>}
                </Box>
            </Grid>
            <Grid item>
                <PostRightPanel name = {data.name} date = {data.date}> </PostRightPanel>
            </Grid>
            
        </Grid>
        
    )
}

export default RequestQuote;