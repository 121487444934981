import React from 'react'
import { Grid, Paper, styled, Typography,  Button, ButtonBase} from '@material-ui/core'
import { Link } from 'react-router-dom';

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '128',
    maxHeight: '128',
  });

function PostComment({isAsker, name, comment, offer, postOwner, commenterId}) {
    const paperStyle = { backgroundColor: "white", width: "100", margin: "20px auto"}
    const hasOffer = offer && offer.trim() != "";
    return (
        <Grid item>
            <Paper variant="outlined" style = {paperStyle}>
                <Grid container spacing={2}>
                <Grid item>
                    <ButtonBase sx={{ width: 128, height: 128 }}>
                    
                    <Grid direction="column">
                        <Link to = {!isAsker ? "/profile/"+commenterId : "#"} style = {{textDecoration: "none"}}>
                            <Img width = "96px" height = "96px" alt="complex" src="../../client.jpg"/>
                            <Typography variant="body2" color="text.secondary">
                                {name} 
                            </Typography>
                        </Link>
                    </Grid>
                    </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography gutterBottom variant="subtitle1" component="div">
                            {isAsker ? "Inquiry:" : (hasOffer ? "Offer ($" + offer + ")" : "Answer:")}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                            {comment}
                            </Typography>
                        </Grid>
                        {!isAsker && postOwner && hasOffer && <Grid container spacing={2} direction="row" justifyContent="flex-end">
                            <Grid item>
                                <Link to ="/payment" style = {{textDecoration: "none"}}>
                                    <Button variant = "contained" style = {{backgroundColor: "green"}} size = "small">
                                        ACCEPT
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default PostComment;
