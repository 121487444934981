import React, {useState} from 'react'
import { Grid, Paper, styled, Avatar, Typography, Box, TextField, Button, ButtonBase, InputAdornment} from '@material-ui/core'
import { firestore} from "../../firebase"
import { useAuth } from '../../contexts/AuthContext'

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '32',
    maxHeight: '32',
  });

function PostComment({id}) {
    const initialValues2 = { offer: "", comment: "" };
    const [formValues2, setFormValues2] = useState(initialValues2);
    const [formErrors2, setFormErrors2] = useState({});
    const [loading, setLoading] = useState(true)
    const { currentUser } = useAuth();
    console.log(id);
    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setFormValues2({ ...formValues2, [name]: value });
    };
    
    const validate2 = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.comment) {
            errors.comment = "Comment is required!";
        }
        if (values.comment.length >= 500) {
            errors.comment = "Maximum character length 500!";
        }
        if (isNaN(values.offer) && values.offer && values.offer.trim() != "" || isNaN(values.offer[0])) {
            errors.offer = "Offer must be a number!"
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errorList = validate2(formValues2);
        if (Object.keys(errorList).length === 0) {
            setFormErrors2({});
            setLoading(true);
            const messagesRef = firestore.collection('Posts').doc(id).collection("Comments");
            const today = new Date();
            const date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();
            const doc = await messagesRef.add({
                Comment: formValues2.comment,     
                name: currentUser.displayName,          
                date: new Date().getTime(),
                Offer: formValues2.offer || "",
                uid: currentUser.uid,
            })
            setFormValues2({["offer"]: "" });
        setFormValues2({["comment"]: "" });
        } else{
            setFormErrors2(errorList);
        }
        setLoading(false);
    }

    const paperStyle = { backgroundColor: "white", width: "100", margin: "10px auto"}
    return (
        <Grid item>
            <Typography>
            Make An Offer
            </Typography>
            <Paper variant="outlined" style = {paperStyle}>
                <Grid container spacing={2}>
                <Grid item>
                    <ButtonBase>
                    <Grid direction="column">
                        <Img width = "48px" height = "48px" alt="complex" src="../../client.jpg"/>
                    </Grid>
                    </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container>
                        <Grid item xs = {2} >
                            <TextField
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  }}
                                  size="small"
                                variant="outlined"
                                fullWidth
                                label="offer"
                                name="offer" value={formValues2.offer} onChange={handleChange2} error = {Boolean(formErrors2?.offer)} helperText = {formErrors2?.offer} fullWidth label='Offer' placeholder="Offer"/>
                        </Grid>
                        <Grid item xs = {12}>
                            <TextField
                            size="small"
                            multiline rows={7} 
                                variant="outlined"
                                fullWidth
                                label="offer"
                                name="comment" value={formValues2.comment} onChange={handleChange2} error = {Boolean(formErrors2?.comment)} helperText = {formErrors2?.comment} fullWidth label='Comment (max 500 characters)' placeholder="Comment"/>
                        </Grid>
                        <Grid item xs = {12} >
                            <Button type="submit"
                            onClick = {handleSubmit}
                            fullWidth
                            variant="contained"
                            style={{ marginTop: 20, marginBottom: 5 }}
                            color = 'primary'>
                                Post Offer
                            </Button>
                        </Grid>
                </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default PostComment;
