import React, { useRef, useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import { Link } from 'react-router-dom';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../../contexts/AuthContext'
import { firestore} from "../../firebase"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '90%',
    height: '80vh',
    margin: 'auto'
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto'
  }
});

const Chat = () => {
  const classes = useStyles();
  
  const { currentUser } = useAuth();
  const { uid, photoURL } = currentUser;
  const id = window.location.href.substring(window.location.href.search("/chat/") + 6);
  const inChat = id != "default"
  const [messagesRef, setMessagesRef] = useState({});
  const [messages, setMessages] = useState({});
  const [chats, setChat] = useState({});
  const [profile, setProfile] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);
  const [searchBar, setSearchBar] = useState("");
  useEffect(() => {
      firestore.collection('Users').doc(id).get().then(async (profileCollection) => {
        console.log("RUNNING FIRST READ")
        setProfile(profileCollection.data());
        if (inChat) {
          let id_of_messages = ""
          await firestore.collection('Chat').where("uid1", "==", uid).where("uid2", "==", id).limit(1).get().then(async (collection) => {
            console.log("RUNNING SECOND READ")
            if (collection.empty) {
              await firestore.collection('Chat').where("uid1", "==", id).where("uid2", "==", uid).limit(1).get().then(async (collection2) => {
                console.log("RUNNING THIRD READ")
                if (collection2.empty) {
                  const doc = await firestore.collection('Chat').add({
                    uid1 : uid,
                    uid2 : id,
                    uids : [uid, id],
                    names : [currentUser.displayName, profileCollection.data().username]
                  });
                  
                  await doc.collection('Messages').add({});
                  console.log(doc)
                  id_of_messages = doc.id;
                } else {
                  id_of_messages = collection2.docs[0].id
                }
              });
            } else {
              id_of_messages = collection.docs[0].id
            }
            
      });;
      const ref = firestore.collection('Chat').doc(id_of_messages).collection("Messages")
      setMessagesRef(ref)
      ref.orderBy('createdAt').limit(25).onSnapshot((doc) => {
        setMessages(doc.docs);
      });;
        }
    });;
    firestore.collection('Chat').where("uids", "array-contains", uid).onSnapshot((doc) => {
      setChat(doc.docs);
    });
  }, [refreshKey]); 

  const [formValue, setFormValue] = useState('');

  const sendMessage = async (e) => {
    if (formValue.trim() == "") return;

    e.preventDefault();

    await messagesRef.add({
      text: formValue,
      createdAt: new Date().getTime(),
      uid,
      photoURL
    })

    setFormValue('');
  }

  function hello() {
    setRefreshKey(oldKey => oldKey +1)
  }

  function searchFilter(element) {
    const filterText = searchBar.toLowerCase()
    return element.data().names[0].toLowerCase().startsWith(filterText) || element.data().names[1].toLowerCase().startsWith(filterText)
  }

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setSearchBar(value);
  };

  return (
    <Grid 
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '86vh' }}
  >
        <Grid container component={Paper} className={classes.chatSection}>
            <Grid item xs={3} className={classes.borderRight500}>
                <List>
                    {profile && <ListItem button key={profile.username}>
                        <ListItemIcon>
                        <Avatar alt={profile.username}src="https://material-ui.com/static/images/avatar/1.jpg" />
                        </ListItemIcon>
                        <ListItemText primary={profile.username}></ListItemText>
                    </ListItem>}
                </List>
                <Divider />
                <Grid item xs={12} style={{padding: '10px'}}>
                    <TextField id="outlined-basic-email" label="Search" variant="outlined" fullWidth onChange={handleChange}/>
                </Grid>
                <Divider />
                <List>
                  {chats && Object.keys(chats).length > 0 && chats.filter(searchFilter).map(msg => <Link to = {msg.data().uid1 === currentUser.uid ? msg.data().uid2 : msg.data().uid1} style = {{textDecoration: "none"}}>
        <span onClick = {hello}> <SideChat key={msg.id} message={msg.data()}/> </span> </Link>)}
                </List>
            </Grid>
            <Grid item xs={9}>
                <List className={classes.messageArea}>
                    {inChat && messages && Object.keys(messages).length > 0 && messages.map(msg => <ChatMessage key={msg.data().id} message={msg.data()}/>)}
                </List>
                <Divider />
                {inChat && <Grid container style={{padding: '20px'}}>
                    <Grid item xs={11}>
                        <TextField value={formValue} onChange={(e) => setFormValue(e.target.value)} id="outlined-basic-email" label="Type Something" fullWidth />
                    </Grid>
                    <Grid xs={1} align="right">
                        <Fab onClick={sendMessage} color="primary" aria-label="add"><SendIcon /></Fab>
                    </Grid>
                </Grid>}
            </Grid>
        </Grid>
      </Grid>
  );
}

function ChatMessage(props) {
  const { currentUser } = useAuth();
  const { text, uid, createdAt } = props.message;
  const date = new Date(createdAt);
  const time = date.getHours() + ":" + date.getMinutes()

  const messageClass = uid === currentUser.uid ? 'right' : 'left';

  return (<>
    <ListItem autoFocus={true}>
      
        <Grid container>
            <Grid item xs={12}>
                <ListItemText align={messageClass}primary={text}></ListItemText>
            </Grid>
            <Grid item xs={12}>
                <ListItemText align={messageClass} secondary={time}></ListItemText>
            </Grid>
        </Grid>
    </ListItem>
  </>)
}

function SideChat(props) {
  const { currentUser } = useAuth();
  const { uid1, uid2, names } = props.message;
  const name = uid1 === currentUser.uid ? names[1] : "Alex Zheng";

  return (
      
    <ListItem button key="RemySharp">
        <ListItemIcon>
            <Avatar alt={name} src="https://material-ui.com/static/images/avatar/1.jpg" />
        </ListItemIcon>
        <ListItemText primary={name}>{name}</ListItemText>
        <ListItemText secondary="online" align="right"></ListItemText>
    </ListItem>
  )
}



export default Chat;