import React from 'react'
import { Grid, Paper, styled, Avatar, Typography, Box, TextField, Button} from '@material-ui/core'
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'

function PostRightPanel({name, date}) {
    const { profile } = useAuth();
    return (
        <Grid item>
            <Paper style={{width: 200, height: 250}}>
                <Grid>
                    <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            {"Posted by " + name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {"Posted on " + date}
                        </Typography>
                        </Grid>
                        {profile.isLawyer && <Grid item>
                        <Typography sx={{ cursor: 'pointer' }} variant="body2">
                        <Link to='/billboard'>
                            View all Questions/Answers
                        </Link>
                        </Typography>
                        </Grid>}
                    </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default PostRightPanel;

