import React from 'react'
import { Grid, Paper, styled, Avatar, Typography, Box, TextField, Button} from '@material-ui/core'
import { Link } from 'react-router-dom';

function BillboardBase({title, replies, activity, id}) {
    if (replies === undefined) {
        replies = 0;
    }
    return (
        <Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
            <Grid container wrap="nowrap" spacing={2} style = {{margin:"auto"}}>
                <Grid item xs ={9} >
                    <Link to= {'/posts/'+id} style={{color: "#000000", textDecoration: 'none' }}>
                        <Typography variant = {"h6"} noWrap>{title}</Typography>
                    </Link>
                </Grid>
                <Grid item xs = {2}>
                    <Typography align = "center" variant = {"h6"} noWrap>{replies}</Typography>
                </Grid>
                <Grid item xs = {2}>
                    <Typography align = "center" variant = {"h6"} noWrap>{activity}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default BillboardBase;