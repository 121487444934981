import React from 'react'
import { Grid, Paper, Typography, Avatar, TextField, Box, ButtonBase, styled} from '@material-ui/core'

import PostRightPanel from '../Posts/PostRightPanel';
import PostComment from '../Posts/PostComment';
const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '128',
    maxHeight: '128',
  });

const RequestQuote = () => {

    const message = "I am working for a company in Dubai since 2009. I am on an unlimited contract and planning to resign by the end of next month. \n The company is going thru financial issues for 6 years and the employee benefits are not settled for all employees who resigned in the last few years. \n I need your advice on how to begin the legal process and get the gratuity and other pending benefits from the company if I would have to proceed legally. \n I shall tender my resignation by end of Feb 2022. Also, would like to know the expenses incurred by me if I am moving forward legally by hiring a lawyer."
    const message2 = "It's bigger than black and white It's a problem with the whole way of life It can't change overnight But we gotta start somewhere Might as well gon' 'head start here We done had a hell of a year I'ma make it count while I'm here God is the only man I fear"
      

    return (
        <Grid container spacing={2} direction="row" justifyContent="space-evenly" alignItems="center">
            <Grid item>
                <Box sx={{ margin: 50, flexGrow: 1, overflow: 'hidden', px: 3, width: 1200, mt: 15, backgroundColor: "white"}}>
                    <PostComment name = "Alex Zheng" isAsker = {true} comment ={message}></PostComment>
                    Responses
                    <PostComment name = "Demarcus" isAsker = {false} comment ={message2}></PostComment>
                    <PostComment name = "Jimmy Butler" isAsker = {false} comment ={message2}> </PostComment>
                    <PostComment name = "Tyrion Lann" isAsker = {false} comment ={message2}> </PostComment>
                    <PostComment name = "Griffy" isAsker = {false} comment = {message2}> </PostComment>

                </Box>
            </Grid>
            <PostRightPanel name = "Alex Zheng" date = "Feb 3 2022"/>
        </Grid>
        
    )
}

export default RequestQuote;