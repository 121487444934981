import * as React from 'react';
import {Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Grid, Box, Typography, Container, ThemeProvider, createTheme} from '@material-ui/core'
import { Lock } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        CSC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp() {
  const initialValues2 = { username: "", email: "", password: "" };
  const [formValues2, setFormValues2] = useState(initialValues2);
  const [formErrors2, setFormErrors2] = useState({});
  const [loading, setLoading] = useState(true);
  const { resetPassword } = useAuth();

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormValues2({ ...formValues2, [name]: value });
  };

  const validate2 = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    return errors;
  };

  async function handleSubmit2 (e) {
    e.preventDefault();
    try {
      setFormErrors2({});
      setLoading(true);
      await resetPassword(formValues2.email);
      setFormErrors2({email: "Success!"});
    } catch {
      setFormErrors2({email: "Invalid Email!"})
    }
    setLoading(false);
  };

  return (
    <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '80vh' }}
  >
  
    <Grid item xs={11}>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <Lock/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit2} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  autoComplete="given-name"
                  required
                  fullWidth
                  id="username"
                  autoFocus
                  name="email" value={formValues2.email} onChange={handleChange2} error = {Boolean(formErrors2?.email)} helperText = {formErrors2?.email} fullWidth label='Email' placeholder="Enter your email"
                />
              </Grid>
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ marginTop: 20, marginBottom: 5 }}
              color = 'primary'
              
            >
              Send Email Confirmation
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2">
                  Return To Login Page
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        
      </Container>
    </ThemeProvider>
    </Grid>`
    </Grid>
  );
}