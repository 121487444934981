import React from 'react';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <h1>Get Help with your Legal Cases</h1>
      <p>LegalGlow connects lawyers and clients across Canada</p>
    </div>
  );
}

export default HeroSection;