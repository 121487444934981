import React, { useContext, useState, useEffect } from 'react'
import { Grid, Paper, styled, Avatar, Typography, Box, TextField, Button} from '@material-ui/core'
import BillboardBase from '../BillboardBase'
import { firestore} from "../../firebase"
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function Billboard() {
  const [messages, setMessages] = useState({});
  useEffect(() => {
      firestore.collection('Posts').orderBy('date').limit(25).get().then((collection) => {
          setMessages(collection.docs);
      });;
  }, []); 

    return (
      <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3, width: 1200, margin: "auto", mt: 15}}>
        <BillboardBase title = "Title" views = "Views" replies = "Replies" activity = "Activity"> </BillboardBase>
        {Array.isArray(messages) && messages.map(msg => <BillboardBase id = {msg.id} activity={msg.data().date} title={msg.data().text}/>)}
      </Box>
      );
}

export default Billboard;