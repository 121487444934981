import React from 'react'
import { Grid, Paper, Typography, TextField, Button} from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext'
import { firestore} from "../../firebase"

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const RequestQuote = () => {
    const initialValues2 = { content : "" };
    const [formValues2, setFormValues2] = useState(initialValues2);
    const [formErrors2, setFormErrors2] = useState({});
    const [loading, setLoading] = useState(true)
    const history = useHistory();
    const { currentUser } = useAuth();
    
    
    const [click, setClick] = useState(false);

    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setFormValues2({ ...formValues2, [name]: value });
      };
    
    const validate2 = (values) => {
        const errors = {};
        if (!values.content) {
            errors.content = "Field is empty!";
        } else if (values.content.length >= 750) {
            errors.content = "Maximum character length is 750!"
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errorList = validate2(formValues2);
        if (Object.keys(errorList).length === 0) {
            setFormErrors2({});
            setLoading(true);
            const messagesRef = firestore.collection('Posts');
            const { uid } = currentUser;
            const today = new Date();
            const date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();
            const id = await messagesRef.add({
                text: formValues2.content,     
                name: currentUser.displayName,          
                date: date, 
                uid,
            })
            await id.collection("Comments").add({});
            history.push("/posts/"+id.id);
        } else{
            setFormErrors2(errorList);
        }
        setLoading(false);
    }

    const paperStyle = { backgroundColor: "white", width: "100vh" , margin: "20px auto", height: "70vh" }
    const headerStyle = { margin: 0 }
    const avatarStyle = { backgroundColor: '#1bbd7e' }
    const marginTop = { marginTop: 5 }

    const bannerStyle =
        {
          height: '30%',
          width: '100%'
        }
      

    return (

    <Grid style = {paperStyle} direction="column" spacing = {10}>

        <Grid item align='center'>
            <Paper variant="outlined">
                <img src= '../../../handshake.png' style = {bannerStyle} />
            </Paper>
                <h1 style={headerStyle}>Get advice or quote from a lawyer</h1>
            </Grid>
        <Grid item xs = {12}>
            <Paper>
                <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs>
                    <Typography>{"Receive a quote from multiple competitive offers to save time and money. Decide between lawyers’ offers based on experience and price to work with the best lawyer for your case. "}</Typography>
                </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item>
            <form>
            <   TextField label="Outlined" multiline rows={7} name="content" style = {{width: "100%"} } onChange = {handleChange2} error = {Boolean(formErrors2?.content)} helpertext = {formErrors2?.content} label='Describe your case and request quote (max 750 characters)' placeholder="Describe your case and request quote" />
            </form>
        </Grid>
        <Grid item>
        <Button onClick = {handleSubmit} type='submit' variant='contained' color='primary'>Submit Request </Button>
        </Grid>
    </Grid>
        
    )
}

export default RequestQuote;