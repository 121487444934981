import * as React from 'react';
import {Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Grid, Box, Typography, Container, ThemeProvider, createTheme, makeStyles} from '@material-ui/core'
import { Lock } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        CSC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
      border: "1px solid grey",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      backgroundColor: "white",
  },
}));

const theme = createTheme();

export default function SignUp() {
  const classes = useStyles();
  const initialValues = { username: "", email: "", password: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(true)
    const { signup, updateProfile,  updateUserData} = useAuth()
    const history = useHistory();

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
    };

    const validate = (values) => {
      const errors = {};
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!values.username) {
        errors.username = "Name is required!";
      }
      if (!values.email) {
        errors.email = "Email is required!";
      } else if (!regex.test(values.email)) {
        errors.email = "This is not a valid email format!";
      }
      if (!values.password) {
        errors.password = "Password is required";
      } else if (values.password.length < 4) {
        errors.password = "Password must be more than 4 characters";
      } else if (values.password.length > 10) {
        errors.password = "Password cannot exceed more than 10 characters";
      }
      return errors;
    };

    async function handleSubmit(e) {
      e.preventDefault();
      const errorList = validate(formValues);
      if (Object.keys(errorList).length === 0) {
        try {
          setFormErrors({});
          setLoading(true);
          await signup(formValues.email, formValues.password);
          await updateProfile(formValues);
          formValues.password = "";
          formValues.isLawyer = false;
          await updateUserData(formValues);
          history.push("/");
        } catch (exception) {
          errorList.email = JSON.stringify(exception);

        }
      } else {
        setFormErrors(errorList);
      }

      setLoading(false);
    }

  return (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '90vh' }}
  >
  
  <Grid item xs={11} className = {classes.container}>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <Lock/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up As A Client
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 5 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  autoComplete="given-name"
                  required
                  fullWidth
                  id="username"
                  autoFocus
                  name="username" value={formValues.username} onChange={handleChange} error = {Boolean(formErrors?.username)} helperText = {formErrors?.username} label='Full Name' placeholder="Enter your name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  name="email" value={formValues.email} onChange={handleChange} error = {Boolean(formErrors?.email)} helperText = {formErrors?.email} fullWidth label='Email' placeholder="Enter your email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Password"
                  name="password" type="password" value={formValues.password} onChange={handleChange} error = {Boolean(formErrors?.password)} helperText = {formErrors?.password} fullWidth label='Password' placeholder="Enter your password"/>
              </Grid>
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ marginTop: 20, marginBottom: 5 }}
              color = 'primary'
              
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        
      </Container>
    </ThemeProvider>
    </Grid>`
    </Grid>
  );
}